import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { EmailComponent, PhoneComponent, AreaTooltipComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { subYears, isAfter } from 'date-fns';
import { Education } from 'pages/Person/components';
import { TLinkEntity } from 'components/ListPage';
import { getWarningImprover } from 'components/ListPage/components/Form';
import { ClearImprover } from 'lib/improvers';
import { TopMessage } from 'components/TopMessages';

export type Keys = (typeof entities.person.columns)[number] | 'bahai_personid' | 'state.bahai_stateregionid';

export const ARCHIVE = 370790006;
export const INVITED = 370790001;
export const REGISTERED = 370790008;
const recordStatusIsArchive = <Trans>Record Status is Archive</Trans>;

export const isActive = (data: Record<Keys, any>) => Number(data.bahai_statuscode) !== ARCHIVE;

export const isNotEditable = (data: Record<Keys, any>) => (!isActive(data) ? recordStatusIsArchive : false);

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_email', 'bahai_id'];

export const columns = [...entities.person.columns, 'state.bahai_stateregionid'];
export const extra = (data: Record<Keys, any>) => <Education id={data.bahai_personid} />;

export const links = {
  state: entities.state.columns,
} as TLinkEntity;

export const config: AdditionalConfigType<Keys | 'systemuserid'> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink
        to={r.person({
          id: data.bahai_personid || (data as Record<string, any>)['_bahai_personid_value'] || 0,
        })}
      >
        {data.bahai_id || (data as Record<string, any>)['person.bahai_id']}
      </HistoryLink>
    ),
  },
  bahai_name: { searchable: true },
  bahai_firstname: { isRequired: true },
  bahai_lastname: { isRequired: true },
  bahai_email: { component: EmailComponent, isRequired: true, searchable: true },
  bahai_additionalemail: { component: EmailComponent },
  bahai_phone: {
    isRequired: (values) => !values.bahai_phoneinput,
    component: (props) => (
      <PhoneComponent {...props} name={props.data.bahai_phone ? 'bahai_phone' : 'bahai_phoneinput'} />
    ),
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_phoneinput: { isDisabled: (_, data) => !!data?.id, component: PhoneComponent },
  bahai_profileimage: { hiddenForTable: true, excludeFromListQuery: true },
  bahai_dateofbirth: {
    fieldProps: () => ({ canSelectFutureDate: false }),
    isRequired: true,
  },
  bahai_nickname: { fieldProps: () => ({ maxLength: 30 }) },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_gendercode: { isRequired: true },
  bahai_statusnote: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_zipcode: {
    isRequired: true,
  },
  bahai_city: {
    isRequired: true,
  },
  bahai_stateid: {
    isRequired: true,
  },
  bahai_addressline: {
    isRequired: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({ maxLength: 500, className: classes.long }),
  },
  bahai_yearscompleted: { sortable: false },
  bahai_internationalcountry: { isRequired: true, fieldProps: () => ({ maxLength: 100 }) },
  bahai_international: {
    fieldProps: ({ classes }) => ({ renderAs: 'Switch', className: classes.long }),
  },
  bahai_homeuniversitystate: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({ maxLength: 500, className: classes.long }),
  },
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_email: rules.compose([rules.required, rules.email, rules.maxLength(100)]),
  bahai_phone: rules.compose([
    rules.conditionalRule((values) => !values.bahai_phoneinput, rules.required),
    rules.phone,
  ]),
  bahai_additionalemail: rules.compose([rules.email, rules.maxLength(100), rules.alternativeEmail('bahai_email')]),
  bahai_dateofbirth: rules.compose([rules.required, rules.pastDate]),
  bahai_firstname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_nickname: rules.maxLength(30),
  bahai_note: rules.maxLength(2000),
  bahai_gendercode: rules.required,
  bahai_city: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_stateid: rules.required,
  bahai_addressline: rules.compose([rules.required, rules.maxLength(500)]),
  bahai_zipcode: rules.compose([rules.required, rules.maxLength(10)]),
  bahai_internationalcountry: rules.compose([rules.required, rules.maxLength(100)]),
  bahai_homeuniversitystate: rules.maxLength(500),
};

export const getStatusFields = (): Keys[] => ['bahai_statuscode'];
export const getContactFields = (data: Record<Keys, any>) => [
  'bahai_email',
  ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : ['bahai_phone']) as Keys[]),
];
export const getCommonFields = (): Keys[] => ['ownerid', 'bahai_isgpregionid', 'bahai_unitid', 'createdon'];

export const getDetailsConfig: FormConfigGetter<Keys> = (data: Record<Keys, any>) => [
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_additionalemail',
      'bahai_dateofbirth',
      'bahai_age',
      'bahai_gendercode',
      'bahai_statusnote',
      'bahai_note',
    ],
  ],
  [
    <Trans>Address Information</Trans>,
    [
      'bahai_international',
      ...((data.bahai_international
        ? ['bahai_internationalcountry']
        : [
            'bahai_addressline',
            'bahai_city',
            'bahai_stateid',
            'bahai_zipcode',
            'state.bahai_stateregionid',
          ]) as Keys[]),
      'bahai_homeuniversitystate',
    ],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data: Record<Keys, any>) => [
  [
    <Trans>Profile Info</Trans>,
    [
      'bahai_email',
      'bahai_additionalemail',
      'bahai_phone',
      ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : []) as Keys[]),
    ] as Keys[],
  ],
  [
    <Trans>Personal Information</Trans>,
    ['bahai_firstname', 'bahai_lastname', 'bahai_nickname', 'bahai_dateofbirth', 'bahai_gendercode', 'bahai_note'],
  ],
  [
    <Trans>Address Information</Trans>,
    [
      'bahai_international',
      ...((data.bahai_international
        ? ['bahai_internationalcountry']
        : ['bahai_addressline', 'bahai_city', 'bahai_stateid', 'bahai_zipcode']) as Keys[]),
      'bahai_homeuniversitystate',
    ],
  ],
];

export const getTopMessages = (data: Record<string, any>) => {
  const messages = [] as TopMessage[];
  if (!isActive(data)) messages.push({ message: recordStatusIsArchive });
  if (isAfter(data.bahai_dateofbirth, subYears(new Date(), 18)))
    messages.push({
      message: <Trans>Person is under the age of 18</Trans>,
    });
  return messages;
};

export const WarningsImprover = getWarningImprover(
  ['bahai_phoneinput'],
  (values, setWarnings) => {
    if (values.bahai_phoneinput) {
      setWarnings([<Trans>Phone number is invalid. Please check and correct.</Trans>]);
    }
  },
  true
);

export const FormImprover = () => (
  <>
    <ClearImprover
      sensitiveFields={['bahai_international']}
      fieldsToClear={['bahai_internationalcountry']}
      clearRequired={({ bahai_international }) => bahai_international === false}
    />
    <ClearImprover
      sensitiveFields={['bahai_international']}
      fieldsToClear={['bahai_addressline', 'bahai_city', 'bahai_stateid', 'bahai_zipcode']}
      clearRequired={({ bahai_international }) => bahai_international === true}
    />
  </>
);
