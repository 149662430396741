import SinglePage from 'components/SinglePage';
import * as config from 'schemas/application';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Keys } from 'schemas/application';
import { TopMessage } from 'components/TopMessages';
import { useRecord } from 'lib/record';
import { useNotifications } from 'providers/NotificationsProvider';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { createPortal } from 'react-dom';
import { Field, Form } from 'react-final-form';
import { Modal } from 'components/Modal';
import { ReactComponent as Icon } from './status.svg';
import * as rules from 'lib/rules';
import { createSelect } from 'components/Form/Select';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';
import { TEntityName } from 'lib';
import { useParams } from 'react-router-dom';

const options = (meta.meta as unknown as Record<TEntityName, TAppEntityMeta>).application.fields
  .bahai_applicationstatuscode.options;

const final_statuses = [370790002, 370790003];

const useChangeStatus = (id: string) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord('application');
  const [data, setData] = useState<Record<string, any> | null>(null);
  const close = useCallback(() => setData(null), []);
  const { addActionCompleted, addActionFailed, addActionUncompleted } = useNotifications();

  const apply = useCallback(
    (formData: Record<string, any>) => {
      setLoading(true);
      patch(formData, id, data?.eTag)
        .then(() => {
          setLoading(false);
          addActionCompleted();
          reloadRef.current && reloadRef.current();
          close();
        })
        .catch(() =>
          addActionFailed(t('Application is changed by another Coordinator. Please exit and refresh the page'))
        )
        .finally(() => setLoading(false));
    },
    [patch, id, data?.eTag, addActionCompleted, close, addActionFailed, t]
  );

  const action: Action = useMemo(
    () => ({
      name: 'change_status',
      title: t('Change Status'),
      onClick: ({ reload, selectedItems: [{ bahai_applicationstatuscode, '@odata.etag': eTag }] }) => {
        if (final_statuses.includes(Number(bahai_applicationstatuscode))) {
          addActionUncompleted(t('Application Status can`t be changed from Ready or Deferred'));
        } else {
          reloadRef.current = reload;
          setData({ bahai_applicationstatuscode, eTag });
        }
      },
      Icon,
      type: ActionType.CUSTOM_ACTION,
      allowedDevices: AllowedDevices.All,
      actionContext: ActionContext.SinglePage,
      order: 10,
    }),
    [addActionUncompleted, t]
  );

  const component = useMemo(
    () => createSelect(new Map(options.filter((v) => v[0] !== String(data?.bahai_applicationstatuscode)))),
    [data?.bahai_applicationstatuscode]
  );

  const content = createPortal(
    !!data && (
      <Form
        onSubmit={apply}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change Status')}
              portal={false}
              onClose={close}
              loading={loading}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: close,
                },
              ]}
            >
              <Field
                name="bahai_applicationstatuscode"
                component={component}
                label={t('Status')}
                validate={rules.required}
                required
                isClearable={false}
              />
            </Modal>
          </form>
        )}
      />
    ),
    document.body
  );

  return { content, action };
};

export const Application = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const getTopMessages = useCallback(
    (data: Record<Keys, any>): TopMessage[] => (data.bahai_isdraft ? [{ message: t('Draft Application') }] : []),
    [t]
  );

  const { action, content } = useChangeStatus(id);

  const getActions = useCallback((baseActions: Action[]) => baseActions.concat(action), [action]);

  return (
    <>
      {content}
      <SinglePage
        entityName="application"
        displayEdit={true}
        displayRemove={true}
        {...config}
        mainSectionName={<Trans>Basic Information</Trans>}
        getTopMessages={getTopMessages}
        getActions={getActions}
        allowDraft
      />
    </>
  );
};
