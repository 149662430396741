import { routes as r, TRouteName } from 'domain/routes';
import { NavLink } from 'react-router-dom';
import classes from './menu.module.scss';
import cx from 'classnames';
import { ReactNode, SVGProps, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as PersonIcon } from './icons/person.svg';
import { ReactComponent as ResourceIcon } from './icons/resource.svg';
import { ReactComponent as ApplicationIcon } from './icons/application.svg';
import { ReactComponent as FacilitatorIcon } from './icons/facilitator.svg';
import { ReactComponent as SeminarIcon } from './icons/seminar.svg';
import { ScreenContext } from 'providers/ScreenProvider';
import { IconButton } from 'components/IconButton';
import { Trans } from 'react-i18next';
import { ErrorContext } from 'providers/ErrorProvider';
import { LogoBlock } from 'components/Header';
import { Privilege } from 'lib';
import { useSecurity } from 'providers/AuthProvider';
import { createPortal } from 'react-dom';
import { getEntityNameFromRoute } from 'pages';

const version = process.env.REACT_APP_VERSION;

const items: Partial<Record<TRouteName, [(iconProps: SVGProps<SVGSVGElement>) => ReactNode, ReactNode]>> = {
  applicationList: [ApplicationIcon, <Trans>Applications</Trans>],
  seminarList: [SeminarIcon, <Trans>Seminars</Trans>],
  personList: [PersonIcon, <Trans>Persons</Trans>],
  facilitatorList: [FacilitatorIcon, <Trans>Facilitators</Trans>],
  resourceList: [ResourceIcon, <Trans>Resources</Trans>],
};

const Menu = () => {
  const { isMobile } = useContext(ScreenContext);
  const { isGranted } = useSecurity();
  const [collapsedMobile, setCollapsedMobile] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);

  const mobileToggle = useCallback(() => setCollapsedMobile((v) => !v), [setCollapsedMobile]);

  useEffect(() => {
    isMobile && setCollapsedMobile(true);
  }, [isMobile]);

  const errorContext = useContext(ErrorContext);

  const menuClick = useCallback(() => {
    isMobile && setCollapsedMobile(true);
    errorContext.clearError();
  }, [isMobile, errorContext, setCollapsedMobile]);

  const elements = useMemo(
    () => (
      <>
        {Object.entries(items)
          .filter(([name]) => isGranted(getEntityNameFromRoute(name), Privilege.Read))
          .map(([name, [Icon, label]]) => (
            <NavLink
              id={name}
              className={cx(classes.link, { [classes.collapsed]: collapsedMobile && isMobile })}
              activeClassName={classes.active}
              key={name}
              to={r[name as TRouteName] ? r[name as TRouteName].path : '/'}
              exact={false}
              onClick={menuClick}
            >
              <Icon className={classes.icon} />
              <span className={classes.linkText}>{label}</span>
            </NavLink>
          ))}
        <div className={classes.version}>
          <div>Ver {version}</div>
        </div>
      </>
    ),
    [collapsedMobile, isGranted, isMobile, menuClick]
  );

  const [header, setHeader] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setHeader(document.getElementById('header'));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (isMobile && ref.current && !(event.target instanceof Node && ref.current.contains(event.target))) {
        setCollapsedMobile(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile]);

  if (isMobile) {
    return (
      <div className={classes.mobile}>
        {header &&
          createPortal(<IconButton className={classes.mobileBtn} Icon={MenuIcon} onClick={mobileToggle} />, header)}
        {!collapsedMobile && (
          <div className={classes.mobileHolder}>
            <div className={classes.wrapper} ref={ref}>
              <button type="button" className={classes.close} onClick={mobileToggle}>
                <DismissIcon />
              </button>
              <div className={classes.logoWrapper}>
                <LogoBlock />
              </div>
              <div className={classes.elements}>{elements}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <aside className={classes.root}>{elements}</aside>;
};

export default Menu;
