import { ReactComponent as LockIcon } from './icons/lock.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as DraftIcon } from './icons/draft.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as ExclamationIcon } from './icons/exclamation.svg';
import { ReactComponent as Danger } from './icons/danger.svg';
import { ReactComponent as Success } from './icons/success.svg';
import { ReactComponent as Custom } from './icons/custom.svg';
import classes from './style.module.scss';
import cx from 'classnames';
import { FC, ReactNode, SVGProps } from 'react';

const icons = {
  lock: <LockIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  draft: <DraftIcon />,
  clock: <ClockIcon />,
  exclamation: <ExclamationIcon />,
  danger: <Danger />,
  success: <Success />,
  custom: <Custom />,
};

export type TopMessage = {
  message: ReactNode;
  type?: 'error' | 'warning' | 'success' | 'info' | 'custom';
  Icon?: keyof typeof icons | FC<SVGProps<any>>;
};

export const TopMessages = ({ messages }: { messages: TopMessage[] }) => (
  <div className={classes.root}>
    {messages.slice(0, 1).map(({ message, type = 'info', Icon = 'info' }, index) => (
      <div key={`${index}-${type}`} className={cx(classes.message, classes[type])}>
        <div className={classes.lockIcon}>{typeof Icon === 'string' ? icons[Icon] : <Icon />}</div>
        <div className={classes.text}>{message}</div>
      </div>
    ))}
  </div>
);
