import {
  FC,
  FocusEvent,
  KeyboardEvent,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  RefObject,
  ChangeEventHandler,
  FocusEventHandler,
} from 'react';
import defaultClasses from './select.module.scss';
import { ReactComponent as DismissIcon } from './icons/dismiss.svg';
import { ReactComponent as ChevronIcon } from './icons/chevron.svg';
import { ReactComponent as CheckedIcon } from './icons/checked.svg';
import { ReactComponent as UncheckedIcon } from './icons/unchecked.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { IconButton } from 'components/IconButton';
import { Loader } from 'components/Loader';

type TClasses = typeof defaultClasses;

type TChevron = ({ opened }: { opened: boolean }) => ReactElement;

export type TSelectRenderProps = {
  placeholder?: string;
  classes?: TClasses;
  chevron?: TChevron;
  itemLimit?: number;
  hasError?: boolean;
  readOnly?: boolean;
  loading?: boolean;
};

export type TSelectProps = {
  items: Map<string, string | JSX.Element>;
  onSearch?: (value: string) => void;
  isMultiple?: boolean;
  isClearable?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  createElement?: JSX.Element;
  listRef?: RefObject<HTMLDivElement>;
  CustomIcon?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  isSearchable?: boolean;
  autoCompleteOff?: boolean;
} & TSelectRenderProps;

export const Option = ({
  selected,
  onSelect,
  classes,
  showCheckBox = true,
  children,
}: {
  selected: boolean;
  onSelect: () => void;
  classes: TClasses;
  children?: ReactNode;
  showCheckBox?: boolean;
}) => {
  const onItemSelect = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onSelect();
    },
    [onSelect]
  );

  const checkBox = useMemo(
    () => (selected ? <CheckedIcon className={classes.selected} /> : <UncheckedIcon className={classes.icon} />),
    [classes.icon, classes.selected, selected]
  );

  return (
    <li
      tabIndex={-1}
      className={classes.listItem}
      style={selected ? { color: 'var(--textPrimary)', fontWeight: '600' } : {}}
      onMouseDown={showCheckBox || !selected ? onItemSelect : undefined}
    >
      {showCheckBox && checkBox}
      <span>{children}</span>
    </li>
  );
};

let timer: ReturnType<typeof setTimeout>;

export const Portal: FC<{ children?: ReactNode; redraw: () => void }> = ({ children, redraw }) => {
  useEffect(() => {
    const onScroll = (e: Event) => {
      if ((e.target as HTMLDivElement)?.id !== 'option-list') {
        redraw();
      }
    };
    window.addEventListener('scroll', onScroll, true);
    window.visualViewport?.addEventListener('resize', redraw);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
      window.visualViewport?.removeEventListener('resize', redraw);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, document.body);
};

export const Select = memo(
  ({
    items,
    onSearch,
    classes = defaultClasses,
    //itemLimit = 10,
    isMultiple = false,
    onChange,
    value = [],
    placeholder,
    isClearable = true,
    hasError,
    readOnly,
    createElement,
    loading,
    listRef,
    CustomIcon,
    className,
    isSearchable = false,
    autoCompleteOff = false,
  }: TSelectProps) => {
    const { t } = useTranslation();
    const isTouchDevice = 'ontouchstart' in window || 'msMaxTouchPoints' in navigator || !!navigator.maxTouchPoints;

    const ref = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const clear = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onChange([]);
      },
      [onChange]
    );

    const [listStyles, setListStyles] = useState({});

    const [search, setSearch] = useState('');
    const [isOpened, setOpened] = useState(false);

    const hide = useCallback(() => {
      setOpened(false);
    }, [setOpened]);

    const close = useCallback(() => {
      setSearch('');
      hide();
      ref.current?.blur();
    }, [setSearch, hide]);

    const checkListPosition = useCallback(() => {
      if (wrapperRef.current) {
        const element = document.scrollingElement || document.documentElement;
        const start = element.scrollTop;
        const { left, width, top, bottom } = wrapperRef.current.getBoundingClientRect();
        if (top < 0 || bottom < 0) {
          close();
          setListStyles({});
          return;
        }
        const realHeight = Math.min(window.visualViewport?.height || window.innerHeight, window.innerHeight);
        const maxListHeight = Math.min(Math.floor((realHeight - 41) / 72), 6) * 36;
        setListStyles({
          left: left + 'px',
          ...(bottom + 5 + maxListHeight > window.innerHeight
            ? {
                bottom: window.innerHeight - top + 2 + 'px',
              }
            : { top: bottom + start + 2 + 'px' }),
          maxHeight: maxListHeight + 'px',
          width: width + 'px',
        });
      }
    }, [setListStyles, close]);

    const [selected, setSelected] = useState(-1);

    const show = useCallback(() => {
      checkListPosition();
      setOpened(true);
      if (onSearch || isSearchable) {
        setTimeout(() => ref.current?.focus(), 100);
      }
    }, [checkListPosition, isSearchable, onSearch]);

    useEffect(() => {
      checkListPosition();
    }, [checkListPosition, isOpened]);

    const idList = useMemo(() => Array.from(items.keys()), [items]);

    const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSearch(e.target.value || '');
      },
      [setSearch]
    );

    const filteredItems = useMemo(() => {
      if (isSearchable && search)
        return new Map<string, string | JSX.Element>(
          [...items.entries()].filter((v) =>
            String(typeof v[1] === 'object' ? (v[1].props.children[0].props.children as string) : v[1])
              .toLowerCase()
              .includes(search.toLowerCase().trim())
          )
        );
      return items;
    }, [isSearchable, items, search]);

    const onSelect = useCallback(
      (newValue: string, e?: MouseEvent) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (!isMultiple) setSearch('');
        onChange(isMultiple ? value.concat(newValue) : [newValue]);
        if (!isMultiple || value.length + 1 === idList.length) {
          close();
        }
      },
      [onChange, value, setSearch, isMultiple, close, idList]
    );

    const removeValue = useCallback(
      (itemValue: string) => {
        onChange(value.filter((v) => v !== itemValue));
      },
      [onChange, value]
    );

    const props = useMemo(
      () => ({
        onFocus: (e: FocusEvent) => {
          e.preventDefault();
          e.stopPropagation();
          show();
        },
        onBlur: () => {
          close();
        },
        onChange: onSearchChange,
        value: search,
      }),
      [onSearchChange, search, show, close]
    );

    useEffect(() => {
      if (onSearch) {
        clearTimeout(timer);
        timer = setTimeout(() => onSearch(search.trim()), 300);
      }
    }, [search, onSearch]);

    const onKeyPress = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown' && selected < idList.length - 1) setSelected((v) => v + 1);
        if (e.key === 'ArrowUp' && selected > 0) setSelected((v) => v - 1);
        if (e.key === 'Enter' && selected > -1) {
          onSelect(idList[selected]);
        }
      },
      [selected, idList, onSelect]
    );

    const onDivBlur = useCallback(
      (e: FocusEvent) => {
        if (e.relatedTarget?.id === 'option-list') return;
        if (!e.relatedTarget || (!e.currentTarget.contains(e.relatedTarget as Node) && isOpened)) {
          e.preventDefault();
          e.stopPropagation();
          hide();
        }
      },
      [hide, isOpened]
    );

    const onInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (e.relatedTarget?.id === 'option-list') return;
        !!props.onBlur && props.onBlur();
      },
      [props]
    );

    const onDivClick = useCallback(
      (e: MouseEvent) => {
        if (e.target !== ref.current) {
          if (!isOpened) {
            show();
          } else {
            setSearch('');
            hide();
          }
        }
      },
      [show, isOpened, setSearch, hide]
    );

    if (readOnly) {
      return <div className={classes.readOnlyValue}>{value.map((id) => items.get(id)).join(', ') || '---'}</div>;
    }

    return (
      <div
        ref={wrapperRef}
        className={cx(classes?.wrapper, { [classes.focus]: isOpened }, className, `select_wrapper`)}
        tabIndex={0}
        onClick={onDivClick}
        onBlur={onDivBlur}
      >
        <div className={cx(classes?.controlBox, { [classes.hasError]: hasError })}>
          <div className={classes?.valuesWrapper}>
            {(!!onSearch || isSearchable) && !isOpened && !value[0] ? (
              <div className={classes.placeholder}>{t('Please select value')}</div>
            ) : (
              <></>
            )}
            {(!!onSearch || isSearchable) && isOpened ? (
              <div className={classes?.inputWrapper}>
                <input
                  placeholder={value.length === 0 ? placeholder : ''}
                  ref={ref}
                  onKeyDown={onKeyPress}
                  className={classes?.input}
                  autoComplete={autoCompleteOff ? 'off' : undefined}
                  autoFocus
                  type="text"
                  {...props}
                  onBlur={onInputBlur}
                  name={'autocomplete'}
                />
              </div>
            ) : (
              <>
                {!isMultiple && value[0] && <div className={classes?.singleValue}>{items.get(value[0])}</div>}
                {isMultiple && (!isOpened || !onSearch) && value[0] && (
                  <div className={classes?.singleValue}>{value.map((id) => items.get(id)).join(', ')}</div>
                )}
                {value.length === 0 && !onSearch && <div className={classes.placeholder}>{placeholder}</div>}
              </>
            )}
          </div>
          {value.length > 0 && isClearable && (
            <IconButton className={classes.clear} Icon={DismissIcon} onMouseDown={clear} />
          )}
          <div className={classes?.chevron}>{CustomIcon ? <CustomIcon /> : <ChevronIcon />}</div>
        </div>
        {isOpened && (
          <Portal redraw={isTouchDevice ? checkListPosition : close}>
            <div className={classes.listWrapper} style={listStyles} ref={listRef}>
              <ul id="option-list" className={cx(classes.list)}>
                {(isMultiple || !!onSearch || isSearchable) &&
                  value.map((id) => (
                    <Option
                      showCheckBox={isMultiple}
                      selected={true}
                      key={id}
                      classes={classes}
                      onSelect={() => removeValue(id)}
                    >
                      {items.get(id)}
                    </Option>
                  ))}
                {Array.from(filteredItems)
                  .filter(([id]) => !value.some((v) => String(v) === String(id)))
                  .map(([value, label]) => (
                    <Option
                      showCheckBox={isMultiple}
                      selected={false}
                      key={value}
                      classes={classes}
                      onSelect={() => onSelect(value)}
                    >
                      {label}
                    </Option>
                  ))}
                {idList.length !== 1 &&
                  value.length !== 1 &&
                  idList.filter((v) => !value.includes(v)).length === 0 &&
                  (!isMultiple || value.length === 0) && (
                    <li className={cx(classes?.listItem, classes?.noItem)}>
                      {loading ? (
                        <div className={classes.loaderWrapper}>
                          <Loader withoutLoadingText={true} />
                        </div>
                      ) : (
                        t('No Results')
                      )}
                    </li>
                  )}
              </ul>
              {createElement}
            </div>
          </Portal>
        )}
      </div>
    );
  }
);
