import ListPage from 'components/ListPage';
import * as config from 'schemas/person';
import { ReactNode } from 'react';
import { ApiFilter, TEntityName } from 'lib';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';

const options = (
  meta.meta as unknown as Record<TEntityName, TAppEntityMeta>
).person.fields.bahai_yearscompleted.options.concat([['370790004', 'Alumni']]);

const quickFilters: Array<{ label: ReactNode; filter: ApiFilter }> = options.map(([_, label], index) =>
  index === 0
    ? {
        label,
        filter: {
          condition: [
            { attribute: 'bahai_yearscompleted', operator: 'null' },
            { attribute: 'bahai_statuscode', operator: 'ne', value: '370790006' },
          ],
        },
      }
    : {
        label,
        filter: {
          condition: [
            { attribute: 'bahai_yearscompleted', operator: 'in', value: options.slice(0, index).map(([v]) => v) },
            { attribute: 'bahai_statuscode', operator: 'ne', value: '370790006' },
          ],
        },
      }
);

export const PersonList = () => <ListPage entityName="person" {...{ quickFilters, ...config }} />;
