import classes from './education.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import meta from 'domain/meta.json';
import { TEntityName } from 'lib';
import { TAppEntityMeta } from 'dynamics-meta';
import { SEMINAR_FINISHED, SEMINAR_IN_PROGRESS } from 'schemas/seminar';

const {
  session: { fields },
} = meta.meta as unknown as Record<TEntityName, TAppEntityMeta>;

const years = fields.bahai_yearoffered.options.map((v) => v[0]);

export const Education = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { request } = useApi();
  const { url } = useMetaData('participant');

  const getItems = useCallback(
    () =>
      request<{ value: Record<string, any>[] }>({
        url,
        params: {
          $filter: `_bahai_personid_value eq ${id} and bahai_attendancestatus ne false`,
          $select: 'bahai_attendancestatus',
          $expand:
            'bahai_sessionid($select=bahai_yearoffered;$expand=bahai_seminarid($select=bahai_name,bahai_statuscode))',
        },
      }),
    [id, request, url]
  );
  const [values, setValues] = useState<Record<string, { bahai_name: string; bahai_attendancestatus: boolean }>>({});

  useEffect(() => {
    getItems().then(({ data: { value } }) => {
      setValues(
        value
          .filter(
            (v) =>
              !!v.bahai_sessionid?.bahai_yearoffered &&
              [SEMINAR_FINISHED, SEMINAR_IN_PROGRESS].includes(
                String(v.bahai_sessionid?.bahai_seminarid?.bahai_statuscode)
              )
          )
          .reduce(
            (
              acc,
              {
                bahai_attendancestatus,
                bahai_sessionid: {
                  bahai_yearoffered,
                  bahai_seminarid: { bahai_name },
                },
              }
            ) => ({ [bahai_yearoffered]: { bahai_name, bahai_attendancestatus }, ...acc }),
            {}
          )
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastYearIndex = useMemo(
    () =>
      Math.max(
        ...Object.keys(values)
          .filter((key) => !!values[key]?.bahai_attendancestatus)
          .map((key) => years.indexOf(key))
      ),
    [values]
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>{t('Seminar Progress')}</div>
      <div className={classes.wrapper}>
        <div className={classes.circleBox}>
          {years.map((key, index) => (
            <div
              key={key}
              className={cx(classes.circle, {
                [classes.active]: index <= lastYearIndex,
                [classes.finished]: index <= lastYearIndex || values[key]?.bahai_attendancestatus,
              })}
            />
          ))}
        </div>
        <div className={classes.labels}>
          {years.map((key, index) => (
            <div className={classes.label} key={key}>
              <div className={classes.unit}>{values[key]?.bahai_name || t('TBD')}</div>
              <div className={classes.year}>{t('Year {{index}}', { index: index + 1 })}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
